<!--积分兑换商品-->
<template>
    <div class="mall-goods-shop">
      <!-- 查詢 -->
      <el-row :gutter="10" style="margin-bottom: 20px;">
        <el-col :span="6">
          <el-input v-model="tableParams.kw" placeholder="请输入昵称/手机号/卡券id搜索" @input="kwChange" clearable class="mr15" />
        </el-col>
        <el-col :span="8">
          <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels range-separator="~"
            start-placeholder="兑换日期" end-placeholder="兑换日期" :picker-options="pickerOptions" @change="reload" clearable
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table :data="tableData" border :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }">
         <el-table-column label="兑换时间"  prop="paymentDate" align="center">
        </el-table-column>
        <el-table-column label="兑换人" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name || "-" }}</span>
            <span>&nbsp;|&nbsp;</span>
            <span>{{ scope.row.mobile || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="已兑换卡券" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.buyGoodsName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付方式" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.paymentInfo || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- 需要根据商品核销状态判断是否已核销 -->
            <el-button type="text" size="medium" v-if="scope.row.writeOffId!=null" @click.stop="getWriteOffDetail(scope.row)">核销详情</el-button>
            <el-button type="text" size="medium" v-else @click.stop="handleWriteOff(scope.row)">核销</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <section class="table-footer">
        <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
          :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="currentPage" @current-change="onCurrentPage">
        </el-pagination>
      </section>
      <!-- 编辑弹框 -->
      <el-dialog :title="'核销详情'" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
        <!-- 表单提交 -->
        <el-descriptions title="" :column="1">
          <el-descriptions-item label="核销时间">{{ writeInfo.addDt }}</el-descriptions-item>
          <el-descriptions-item label="核销门店">{{ writeInfo.shopName }}</el-descriptions-item>
          <el-descriptions-item label="操作人">{{ writeInfo.userName }}</el-descriptions-item>
  
        </el-descriptions>
      </el-dialog>
  
    </div>
  </template>
  
  <script>
  import {orderList,wirteOff,writeDetail} from '@/api/point/order'
  import { ObjToOpt } from "@/utils/utils";
  import { pickerOptions, } from "@/db/objs";
  export default {
    data() {
      return {
        ObjToOpt,
        pickerOptions,
        // table表格
        currentPage: 1, // 当前页
        total: 0, // 总条数
        tableData: [], //表格数据
        tableParams: {
          page: 1,
          size: 10,
          kw: "", //查询关键字
          shopId:'',
          businessType:15
        },
        editShow: false, //弹框开关
        dateToDate: [], //日期
        writeInfo:{}//核销信息
  
      };
    },
    created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
    mounted() {
      this.orderList();
    },
    methods: {
      // 【请求】点单商品列表
      orderList() {
        if (this.dateToDate) {
          this.tableParams.dateFrom = this.dateToDate[0];
          this.tableParams.dateTo = this.dateToDate[1];
        } else {
          this.tableParams.dateFrom = "";
          this.tableParams.dateTo = "";
        }
        orderList(this.tableParams).then((res) => {
          if (res.isSuccess == "yes") {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        });
      },
      // 【监听】表格模糊查询
      kwChange: _.debounce(function () {
        this.reload();
      }, 500),
  
      // 【监听】表格重载
      reload() {
        this.tableParams.page = 1;
        this.currentPage = 1;
        this.orderList();
      },
      // 【监听】表格分页点击
      onCurrentPage(page) {
        this.tableParams.page = page;
        this.orderList();
      },
  
      // 【监听】表格条数点击
      onSizeChange(size) {
        this.tableParams.size = size;
        this.reload();
      },
      handleWriteOff(row){
        this.$confirm("是否确定核销该商品?", "核销", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            wirteOff({orderId:row.orderId}).then(res=>{
              if(res.isSuccess == 'yes'){
                this.$message.success('核销成功')
                this.reload()
              }
            })
          });
       
      },
      getWriteOffDetail(row){
        this.editShow = true
        writeDetail({writeOffId:row.writeOffId}).then(res=>{
            this.writeInfo = res.data
        })
      },
  
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .mall-goods-shop {
    padding: 0.15rem;
  }
  
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
  
  .table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.15rem;
  }
  
  .search-L {
    display: flex;
  }
  
  .form-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .export {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  </style>
  
  <style lang="scss">
  .export-success {
    .el-icon-check {
      font-size: 0.48rem;
    }
  }
  </style>