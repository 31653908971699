import request from '@/utils/request'
// 订单列表
export function orderList(data) {
  return request('post', '/apm/point/order/page', data)
}
// 核销
export function wirteOff(data) {
    return request('post', '/apm/point/order/writeOff', data)
  }
//   核销详情

export function writeDetail(data) {
    return request("get", "/apm/point/order/writeOffDetail", data);
  }